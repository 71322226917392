.upgrade-icon-wrap {
  @apply h-44 w-44 mx-auto 
  border-solid rounded-full flex flex-col justify-start items-center;
  border-width: 12px;
  border-color: #04b3b3;

  &::before {
    content: "";
    border-style: solid;
    border-width: 40px;
    border-color: transparent transparent #04b3b3 transparent;
    margin-top: -8px;
  }
  &::after {
    content: "";
    border-style: solid;
    border-width: 25px 18px;
    border-color:  #04b3b3;
    
  }

}