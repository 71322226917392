.copy-notif {
  animation-name: fadeOut;
  animation-duration: 3.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    height: 0;
    opacity: 0;
  }

  20% {
    height: 15px;
    opacity: 1;
  }

  60% {
    height: 15px;
    opacity: 1;
    
  }

  100% {
    height: 0;
    opacity: 0;
  }
}